
import Vue, { PropType } from "vue";
import { SalesOrderAddress } from "@/modules/salesOrder/api/salesOrderShow";

export default Vue.extend({
  name: "AddressCard",
  props: {
    address: {
      type: Object as PropType<SalesOrderAddress | undefined>,
    },
    displayContent: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
    },
  },
});
