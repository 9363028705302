
import Vue, { PropType } from "vue";
import dayjs from "@/plugins/dayjs";
import { SalesOrderHistoryItem } from "@/modules/salesOrder/api/salesOrderShow";

export default Vue.extend({
  name: "SalesOrderHistoryCard",
  props: {
    history: {
      type: Array as PropType<SalesOrderHistoryItem[] | undefined>,
    },
  },
  methods: {
    formatDate(date: string) {
      return dayjs(date).format("dd D MMMM YYYY");
    },
  },
});
