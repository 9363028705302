
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import RequiredClientDialog from "@/modules/client/components/RequiredClientDialog.vue";
import {
  SalesOrder,
  SalesOrderShow,
} from "@/modules/salesOrder/api/salesOrderShow";
import AddressCard from "@/modules/salesOrder/components/AddressCard.vue";
import OrderInformationCard from "@/modules/salesOrder/components/OrderInformationCard.vue";
import SalesOrderHistoryCard from "@/modules/salesOrder/components/SalesOrderHistoryCard.vue";
import SalesOrderItemsCard from "@/modules/salesOrder/components/SalesOrderItemsCard.vue";
import eventBus from "@/application/eventBus";
import { clientSalesOrderIndex } from "@/modules/salesOrder/api/salesOrderIndex";
import { PaginatedRequest } from "@/application/api/getPaginated";

interface ComponentData {
  value: SalesOrder | null;
  isLoading: boolean;
  displayOrderDetails: boolean;
  salesOrders?: SalesOrderNext[];
}

interface SalesOrderNext {
  id: number;
  orderNumber: string;
}

export default Vue.extend({
  name: "SalesOrderDetail",
  components: {
    SalesOrderItemsCard,
    SalesOrderHistoryCard,
    OrderInformationCard,
    AddressCard,
    RequiredClientDialog,
  },
  data: (): ComponentData => ({
    isLoading: true,
    value: null,
    displayOrderDetails: true,
    salesOrders: [],
  }),
  watch: {
    "$route.params": {
      immediate: true,
      deep: true,
      async handler(value) {
        const { salesOrderId, salesOrders } = value;
        await this.getSalesOrder(Number(salesOrderId));

        if (salesOrders) this.salesOrders = JSON.parse(salesOrders);
      },
    },
  },
  computed: {
    ...mapGetters("authorisation", ["client", "isClient"]),
    clientId(): number {
      return this.client?.id;
    },
    currentSalesOderIndex(): number {
      return (
        this.salesOrders?.findIndex((order) => order?.id === this.value?.id) ??
        -1
      );
    },
    previousSalesOrder(): SalesOrderNext | undefined {
      return this.salesOrders?.[this.currentSalesOderIndex - 1];
    },
    nextSalesOrder(): SalesOrderNext | undefined {
      return this.salesOrders?.[this.currentSalesOderIndex + 1];
    },
  },
  methods: {
    ...mapMutations("error", { addValidationErrorToStore: "add" }),
    toggleDisplayOrderDetails() {
      this.displayOrderDetails = !this.displayOrderDetails;
    },
    salesOrderIndex(data: PaginatedRequest) {
      if (!this.client?.id) throw "Client id is not set";
      return clientSalesOrderIndex(data, this.client?.id);
    },
    async getSalesOrder(id: number): Promise<void> {
      try {
        this.isLoading = true;
        const response = await SalesOrderShow({
          salesOrderId: id,
          clientId: this.clientId,
        });
        if (response.data.validationErrors) {
          setTimeout(() => {
            Object.keys(response.data.validationErrors.errors).forEach(
              (key) => {
                this.addValidationErrorToStore({
                  key,
                  message: response.data.validationErrors.errors[key][0],
                });
              }
            );
            (this.$refs.form as any).$refs.form.validate();
          }, 500);
        }
        this.value = response.data.data;
      } catch (error: any) {
        if (error.response?.status === 404) {
          this.$router.push({ name: "notFound" });
          return;
        }
        eventBus.$emit("snackbar", {
          text: this.$t("global.error"),
          color: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
});
