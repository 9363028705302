
import Vue, { PropType } from "vue";
import { SalesOrder } from "@/modules/salesOrder/api/salesOrderShow";

export default Vue.extend({
  name: "OrderInformationCard",
  props: {
    order: {
      type: Object as PropType<SalesOrder>,
      required: true,
    },
    displayContent: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
    },
  },
  computed: {
    salesOrderBase() {
      const { orderDate, salesOrderStatus, source, paymentMethod, reference } =
        this.order;

      return Object.fromEntries(
        Object.entries({
          orderDate,
          salesOrderStatus,
          source,
          paymentMethod,
          reference,
        }).filter(([_, v]) => v != null)
      );
    },
  },
});
