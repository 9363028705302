
import Vue, { PropType } from "vue";
import { SalesOrder } from "@/modules/salesOrder/api/salesOrderShow";

export default Vue.extend({
  name: "SalesOrderItemsCard",
  props: {
    order: {
      type: Object as PropType<SalesOrder | undefined>,
    },
  },
});
